/**
 * @Copyright 2021 @DigiNet
 * @Author XUANLOC
 * @Create 19/8/2021
 * @Example
 */

import { Button, Typography } from 'diginet-core-ui/components';
import React, { useEffect, useRef, useState } from 'react';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Config from '../../../../config';
import * as approvalActions from '../../../../redux/approvals/approvals_actions';
import * as generalActions from '../../../../redux/general/general_actions';
import * as W39F3025Actions from '../../../../redux/W3X/W39F3025/W39F3025_actions';
import Approvals from '../../../approvals/approvals';
import { Combo } from '../../../common/form-material';
import DateBoxPicker from '../../../common/form-material/date-box';
import ActionToolbar from '../../../common/toolbar/action-toolbar';
import Filter from '../../../filter/filter';
import { useStyle } from './style';
import W39F3025GroupGoal from './utils/W39F3025GroupGoal';

const W39F3025 = (props) => {
    const { location } = props;

    const classes = useStyle();

    // const [iPermission, setIPermission] = useState(0);
    const [loadingCboCreateUsers, setLoadingCboCreateUsers] = useState(false);
    const [loadingCboEmployees, setLoadingCboEmployees] = useState(false);
    const [dataForm, setDataForm] = useState([]);
    const [dataCboCreateUsers, setDataCboCreateUsers] = useState({
        rows: [],
        total: 0,
    });
    const [dataCboEmployees, setDataCboEmployees] = useState({
        rows: [],
        total: 0,
    });
    const [dataCboGoalTable, setDataCboGoalTable] = useState([]);
    const [DateFrom, setDateFrom] = useState(null);
    const [DateTo, setDateTo] = useState(null);
    const [DateFrom2, setDateFrom2] = useState(null);
    const [DateTo2, setDateTo2] = useState(null);

    const filter = useRef({
        CreateUserID: '',
        ApprovalStatus: null,
        EmployeeID: '',
        GoalPlanID: '',
    });
    const toolFilter = useRef(null);
    const approvalRef = useRef(null);

    const [dataFilter, setDataFilter] = useState(() => {
        return {
            UserID: Config?.profile?.UserID ?? '',
            ApprovalStatus: filter.current?.ApprovalStatus ?? null,
            EmployeeID: filter.current?.EmployeeID ?? '',
            DepartmentID: '',
            ProjectID: '',
            TransType01ID: '',
            DutyID: '',
            IsRegisterType: null,
            Type: 0,
            VoucherID: '',
            CreateUserID: filter.current?.CreateUserID ?? '',
            GoalPlanID: filter.current?.GoalPlanID ?? '',
            DateFrom: DateFrom ?? null,
            DateTo: DateTo ?? null,
            DateFrom2: DateFrom2 ?? null,
            DateTo2: DateTo2 ?? null,
        };
    });

    const dispatch = useDispatch();
    const dataCboStatus = useSelector((state) => state.approvals.getCboStatus);

    const paramFilterCboCreateUsers = {
        skip: 0,
        limit: 10,
        search: '',
    };

    const paramFilterCboEmployees = {
        skip: 0,
        limit: 10,
        search: '',
    };

    const colorStatusArr = [
        {
            id: 0,
            label: Config.lang('Chua_hoan_thanh'),
            colorCode: Config.color ? Config.color['Success'] : '#00C875',
            colorStr: 'success',
        },
        {
            id: 1,
            label: Config.lang('Qua_han'),
            colorCode: Config.color ? Config.color['Danger'] : '#FF3D71',
            colorStr: 'danger',
        },
        {
            id: 2,
            label: Config.lang('Hoan_thanh'),
            colorCode: Config.color ? Config.color['Info'] : '#0095FF',
            colorStr: 'info',
        },
        {
            id: 3,
            label: Config.lang('Cham_tien_do'),
            colorCode: Config.color ? Config.color['Warning'] : '#FFAA00',
            colorStr: 'warning',
        },
    ];

    const loadPermission = () => {
        dispatch(
            generalActions.getPermission('W39F3025', (isPer) => {
                // setIPermission(isPer);
            })
        );
    };

    useEffect(() => {
        loadPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const VoucherID = location?.state?.voucher_id ?? null;
        const Type = location?.state?.Type ?? null;
        if (VoucherID) {
            loadFormInfo(VoucherID, Type, (dt) => {
                dt.VoucherID = VoucherID;
                approvalRef.current && approvalRef.current.showPopup(dt);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    const loadCboCreateUsers = (isReset) => {
        const param = {
            Type: 'EmployeeID',
            FormID: 'W39F3025',
            Language: Config.language || '84',
            ...paramFilterCboCreateUsers,
        };
        setLoadingCboCreateUsers(true);
        dispatch(
            generalActions.getCboEmployees(param, (err, data) => {
                setLoadingCboCreateUsers(false);
                if (err) return Config.popup.show('ERROR', err);
                if (data) {
                    const rows = data?.rows ?? data;
                    const total = data?.total ?? data?.length;
                    setDataCboCreateUsers({
                        rows: isReset ? rows : dataCboCreateUsers.rows.concat(rows),
                        total,
                    });
                }
            })
        );
    };

    const loadCboStatus = () => {
        const params = {
            FormID: 'STANDARD',
            Language: Config.language || '84',
        };
        dispatch(
            approvalActions.getCboStatus(params, (error) => {
                if (error) {
                    Config.popup.show('ERROR', error);
                    return false;
                }
            })
        );
    };

    const loadCboEmployees = (isReset) => {
        const param = {
            Type: 'EmployeeID',
            FormID: 'W39F3025',
            Language: Config.language || '84',
            ...paramFilterCboEmployees,
        };
        setLoadingCboEmployees(true);
        dispatch(
            generalActions.getCboEmployees(param, (err, data) => {
                setLoadingCboEmployees(false);
                if (err) return Config.popup.show('ERROR', err);
                if (data) {
                    const rows = data?.rows ?? data;
                    const total = data?.total ?? data?.length;
                    setDataCboEmployees({
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total,
                    });
                }
            })
        );
    };

    const loadCboGoalTable = async () => {
        dispatch(
            W39F3025Actions.getGoalTable((err, data) => {
                if (err) return Config.popup.show('ERROR', err);
                if (data) {
                    setDataCboGoalTable(data);
                }
            })
        );
    };

    const onLoadCboFilter = () => {
        loadCboCreateUsers();
        loadCboStatus();
        loadCboEmployees();
        loadCboGoalTable();
    };

    const filterChange = (key, value) => {
        switch (key) {
            case 'CreateUserID':
            case 'EmployeeID':
                filter.current[key] = value?.EmployeeID ?? '';
                break;
            case 'ApprovalStatus':
                filter.current[key] = value?.value ? Number(value?.value) : null;
                break;
            case 'GoalPlanID':
                filter.current[key] = value ?? '';
                break;
            default:
                break;
        }
    };

    const renderFilter = () => {
        return (
            <Filter
                dropdownProps={{
                    ref: (ref) => (toolFilter.current = ref),
                }}
                placeholder={Config.lang('Noi_dung_can_tim_kiem')}
                showHeader={false}
                openOnFieldClick={true}
                onOpenLoaded={onLoadCboFilter}
                renderFilter={() => {
                    return (
                        <>
                            <div>
                                <Combo.LoadMore
                                    dataSource={dataCboCreateUsers}
                                    skip={paramFilterCboCreateUsers.skip}
                                    limit={paramFilterCboCreateUsers.limit}
                                    displayExpr={'EmployeeName'}
                                    keyExpr={'EmployeeID'}
                                    valueExpr={'EmployeeID'}
                                    value={filter.current?.CreateUserID ?? ''}
                                    loading={loadingCboCreateUsers}
                                    stylingMode={'outlined'}
                                    label={Config.lang('Nguoi_tao')}
                                    shrink={true}
                                    placeholder={Config.lang('Chon')}
                                    itemRender={(e) => {
                                        const { data } = e;
                                        if (!data) return null;
                                        return data.EmployeeID + ' - ' + data.EmployeeName;
                                    }}
                                    showClearButton={true}
                                    onValueChanged={(e) => filterChange('CreateUserID', e.value)}
                                    onInputChanged={(e) => {
                                        paramFilterCboCreateUsers.search = e.target.value;
                                        paramFilterCboCreateUsers.skip = 0;
                                        loadCboCreateUsers(true);
                                    }}
                                    onLoadMore={(e) => {
                                        paramFilterCboCreateUsers.skip = e.skip;
                                        paramFilterCboCreateUsers.limit = e.limit;
                                        loadCboCreateUsers();
                                    }}
                                />
                            </div>
                            <div>
                                <Combo
                                    label={Config.lang('Trang_thai')}
                                    shrink={true}
                                    placeholder={Config.lang('Chon')}
                                    dataSource={dataCboStatus}
                                    displayExpr={'AppStatusName'}
                                    keyExpr={'ApprovalStatus'}
                                    valueExpr={'ApprovalStatus'}
                                    onValueChanged={(e) => filterChange('ApprovalStatus', e)}
                                    showClearButton={true}
                                    value={String(filter.current?.ApprovalStatus ?? '')}
                                />
                            </div>
                            <div className={`${classes.flex} ${classes.gap50} ${classes.alignCenter}`}>
                                <Typography type={'h4'} className={`${classes.wSnoWrap} ${classes.subColor}`}>
                                    {Config.lang('Ngay_tao')}
                                </Typography>
                                <DateBoxPicker
                                    label={Config.lang('Tu')}
                                    shrink={true}
                                    dateBoxProps={{ max: DateTo ? DateTo : null }}
                                    onValueChanged={(e) => setDateFrom(e.value)}
                                    showClearButton={true}
                                    placeholder={'DD/MM/YYYY'}
                                    value={DateFrom ?? ''}
                                />
                                <DateBoxPicker
                                    label={Config.lang('Den')}
                                    shrink={true}
                                    dateBoxProps={{ min: DateFrom ? DateFrom : null }}
                                    onValueChanged={(e) => setDateTo(e.value)}
                                    showClearButton={true}
                                    placeholder={'DD/MM/YYYY'}
                                    value={DateTo ?? ''}
                                />
                            </div>
                            <div className={`${classes.divDetailCont}`}>
                                <Typography type={'h4'} className={`${classes.typoDetail} ${classes.subColor}`} fullWidth={false}>
                                    {Config.lang('Chi_tiet')}
                                </Typography>
                                <div style={{ marginTop: 8 }}>
                                    <Combo.LoadMore
                                        dataSource={dataCboEmployees}
                                        skip={paramFilterCboEmployees.skip}
                                        limit={paramFilterCboEmployees.limit}
                                        displayExpr={'EmployeeName'}
                                        keyExpr={'EmployeeID'}
                                        valueExpr={'EmployeeID'}
                                        value={filter.current?.EmployeeID ?? ''}
                                        loading={loadingCboEmployees}
                                        stylingMode={'outlined'}
                                        label={Config.lang('Nhan_vien')}
                                        shrink={true}
                                        placeholder={Config.lang('Chon')}
                                        itemRender={(e) => {
                                            const { data } = e;
                                            if (!data) return null;
                                            return data.EmployeeID + ' - ' + data.EmployeeName;
                                        }}
                                        showClearButton={true}
                                        onValueChanged={(e) => filterChange('EmployeeID', e.value)}
                                        onInputChanged={(e) => {
                                            paramFilterCboEmployees.search = e.target.value;
                                            paramFilterCboEmployees.skip = 0;
                                            loadCboEmployees(true);
                                        }}
                                        onLoadMore={(e) => {
                                            paramFilterCboEmployees.skip = e.skip;
                                            paramFilterCboEmployees.limit = e.limit;
                                            loadCboEmployees();
                                        }}
                                    />
                                </div>
                                <div>
                                    <Combo
                                        label={Config.lang('Bang_muc_tieu')}
                                        shrink={true}
                                        dataSource={dataCboGoalTable}
                                        displayExpr={'GoalPlanName'}
                                        valueExpr={'GoalPlanID'}
                                        onValueChanged={(e) => filterChange('GoalPlanID', e.value)}
                                        showClearButton={true}
                                        value={filter.current.GoalPlanID}
                                        placeholder={Config.lang('Chon')}
                                    />
                                </div>
                                <div className={`${classes.flex} ${classes.gap50} ${classes.alignCenter}`}>
                                    <Typography type={'h4'} className={`${classes.wSnoWrap} ${classes.subColor}`}>
                                        {Config.lang('Giai_doan')}
                                    </Typography>
                                    <DateBoxPicker
                                        label={Config.lang('Tu')}
                                        shrink={true}
                                        dateBoxProps={{ max: DateTo2 ? DateTo2 : null }}
                                        onValueChanged={(e) => setDateFrom2(e.value)}
                                        showClearButton={true}
                                        placeholder={'DD/MM/YYYY'}
                                        value={DateFrom2 ?? ''}
                                    />
                                    <DateBoxPicker
                                        label={Config.lang('Den')}
                                        shrink={true}
                                        dateBoxProps={{ min: DateFrom2 ? DateFrom2 : null }}
                                        onValueChanged={(e) => setDateTo2(e.value)}
                                        showClearButton={true}
                                        placeholder={'DD/MM/YYYY'}
                                        value={DateTo2 ?? ''}
                                    />
                                </div>
                            </div>
                            <div className={`${classes.flex} ${classes.justifyCenter}`} style={{ marginTop: 8 }}>
                                <Button
                                    text={Config.lang('Tim_kiem')}
                                    viewType="outlined"
                                    color={'primary'}
                                    onClick={onChangeDataFilter}
                                    startIcon={'Search'}
                                />
                            </div>
                        </>
                    );
                }}
            />
        );
    };

    const onChangeDataFilter = () => {
        toolFilter.current.instance.close();
        setDataFilter({
            ...dataFilter,
            ApprovalStatus: filter.current?.ApprovalStatus ?? null,
            EmployeeID: filter.current?.EmployeeID ?? '',
            CreateUserID: filter.current?.CreateUserID ?? '',
            GoalPlanID: filter.current?.GoalPlanID ?? '',
            DateFrom: DateFrom ?? null,
            DateTo: DateTo ?? null,
            DateFrom2: DateFrom2 ?? null,
            DateTo2: DateTo2 ?? null,
        });
    };

    const renderFormInfo = (data) => {
        return (
            <div>
                {data?.length > 0 &&
                    data.map((i, idx) => {
                        return <W39F3025GroupGoal key={i?.GroupGoalID ?? idx} data={i} classes={classes} colorStatusArr={colorStatusArr} />;
                    })}
            </div>
        );
    };

    const loadFormInfo = (transID, type, cb) => {
        const params = {
            DivisionID: Config.getDivisionID() ?? '',
            FormID: 'W39F3025',
            UserID: Config?.profile?.UserID ?? '',
            Language: Config?.language ?? '84',
            VoucherID: transID ?? '',
            Type: 0,
            Mode: 0,
            TransType: type ?? '',
        };

        dispatch(
            W39F3025Actions.getDetailData(params, (err, dt) => {
                if (err) {
                    const msg = err?.message ?? Config.lang('Loi_chua_xac_dinh');
                    return Config.popup.show('ERROR', msg);
                }
                if (dt) {
                    setDataForm(dt);
                    cb && cb(dt);
                }
            })
        );
    };

    const onSelectionChanged = (e) => {
        if (!e) return false;
        loadFormInfo(e.VoucherID, e.Type);
    };

    return (
        <>
            <FormGroup>
                <ActionToolbar title={Config.lang('Duyet_muc_tieu')} />
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                        <Approvals
                            ref={approvalRef}
                            parentProps={props}
                            FormID={'W39F3025'}
                            dataFilter={dataFilter}
                            dataForm={dataForm}
                            singleClick
                            allowHistoryApproval
                            filterRender={renderFilter}
                            formInfoRender={renderFormInfo}
                            onSelectionChanged={onSelectionChanged}
                        />
                    </Col>
                </Row>
            </FormGroup>
        </>
    );
};

W39F3025.propTypes = {};

export default W39F3025;
